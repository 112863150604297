import { useState, useEffect } from "react";
import { db, auth } from "../Comp/config";

const Definition = ({ display }) => {
  return display ? (
    <div>
      <h2>What do you know</h2>
      <p>
        Ok, so we have a basic red pill, blue pill situation. I prefer my
        customers know what they are asking for, and of course why the cost is
        what it is. Thus if you choose the blue pill I'll explaina as best I
        can(how deep the rabbit hole goes), and if you choose the red pill we
        assume you already know enough
      </p>
      <button>Red Pill</button>
      <button>Blue Pill</button>
    </div>
  ) : null;
};

const Log = ({ display }) => {
  const [login, setLogin] = useState(false);
  const [email, setEmail] = useState("")
  const [pass, setPass] = useState("")
  const [reenter, setReenter] = useState("")

  const Signin = () => {
  };
  const Signup = e => {
    console.log(email)
  };

  return display ? (
    <div className="auth-box">
      <h2>Sign Up/ Sign In</h2>
      <div className="switch" onClick={() => setLogin(!login)}>
        <h4 className={login ? "off" : "on"}>Sign Up</h4>
        <h4 className={login ? "on" : "off"}>Sign In</h4>
      </div>
      <p>
        {login
          ? "Welcome back"
          : "First things first I need you to create a profile. Nothing to intimate. This will just simplify the process of tracking my customers"}
      </p>
      {login ? (
        <>
          <form onSubmit={Signin}>
            <input type="text" placeholder="Email" className="input" onChange={e=>setEmail(e.target.value)}/>
            <input type="text" placeholder="Password" className="input"  onChange={e=>setPass(e.target.value)}/>
            <input type="submit" value="Sign In" className="button signin" />
          </form>
        </>
      ) : (
        <>
          <form onSubmit={Signup}>
            <input type="text" placeholder="Email" className="input"  onChange={e=>setEmail(e.target.value)}/>
            <input type="text" placeholder="Password" className="input"  onChange={e=>setPass(e.target.value)}/>
            <input
              type="text"
              placeholder="Re-enter password"
              className="input"
              onChange={e=>setReenter(e.target.value)}
            />
            <input type="submit" value="Sign Up" className="button signup" />
          </form>
        </>
      )}
    </div>
  ) : null;
};

const Order = () => {
  return <></>;
};

export const Programming = () => {
  const [display, setDisplay] = useState(false);
  const [login, setLogin] = useState(false);
  const [order, setOrder] = useState(false);
  const [info, setInfo] = useState(false);
  const [templates, setTemplates] = useState(null);
  const [designs, setDesigns] = useState(null);
  const [petPros, setPetPros] = useState(null);

  const LoadProjects = () => null;

  const Cancel = () => {
    setDisplay(false);
    setLogin(false);
    setOrder(false);
    setInfo(false);
  };

  const RunAuth = () => {
    auth.onAuthStateChanged((user) => {
      if (user) {
        setDisplay(true);
        setInfo(true);
      } else {
        setDisplay(true);
        setInfo(false);
        setLogin(true);
      }
    });
  };

  useEffect(() => {
    LoadProjects();
    return () => LoadProjects();
  }, []);

  return (
    <div className="main2">
      {templates ? <div className="block2 blockA"></div> : null}
      {designs ? <div className="block2 blockB"></div> : null}
      {petPros ? <div className="block2 blockA"></div> : null}
      {templates || designs || petPros ? (
        <div className="with_projects blockB">
          <h5>Maybe you thinking of something else</h5>
          <button onClick={RunAuth}>Yes</button>
          {display ? (
            <>
              <div className="backdrop" onClick={Cancel}></div>
              <Log display={login} setDisplay={setLogin} />
              <Definition display={info} setDisplay={setInfo} />
              <Order display={order} setDisplay={setOrder} />
            </>
          ) : null}
        </div>
      ) : (
        <div className="without_projects blockB">
          <h5>What are you thinking</h5>
          <p>
            If your seeing this it means I have no projects available right now
            so tell me what you looking for
          </p>
          <button onClick={RunAuth}>Make request</button>
          {display ? (
            <>
              <div className="backdrop" onClick={Cancel}></div>
              <Log display={login} setDisplay={setLogin} />
              <Definition display={info} setDisplay={setInfo} />
              <Order display={order} setDisplay={setOrder} />
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};
